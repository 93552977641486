import React, { useEffect, useRef } from "react";
import Start from "../../components/TradingPage/Start/Start";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import Grab from "../../components/TradingPage/Grab/Grab";
import Our from "../../components/TradingPage/Our/Our";
import Sub from "../../components/TradingPage/Sub/Sub";
import Tabs from "../../components/TradingPage/Tabs/Tabs";
import Security from "../../components/TradingPage/Security/Security";
import Winner from "../../components/TradingPage/Winner/Winner";

import { initAnimation } from "../../mixins/animationMethods";
import { useLocation } from "react-router-dom";

const TraidingPage = () => {
  const ref = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    document.body.scrollTop = 0;
  }, [pathname]);
  useEffect(() => {
    if (ref !== null && document.documentElement.clientWidth > 1200) {
      initAnimation(ref, 6);
    }
  }, []);

  return (
    <div ref={ref}>
      <NavBar />
      <Start />
      <div id="animBlock_1">
        <Grab />
      </div>
      {/* <div id="animBlock_2">
        <Our />
      </div> */}
      <div id="animBlock_3">
        <Sub />
      </div>
      {/* <div id="animBlock_4">
        <Tabs second={true} />
      </div> */}
      <div id="animBlock_5">
        <Security />
      </div>
      <div id="animBlock_6">
        <Winner />
      </div>
      <Footer />
    </div>
  );
};

export default TraidingPage;
