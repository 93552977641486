import React, { useState } from "react";
import classes from "./Choose.module.scss";
import { useTranslation } from "react-i18next";
const Choose = () => {
  const { t } = useTranslation();
  const [num, setNum] = useState(1);

  return (
    <div className={classes.payment}>
      <div className={"container"}>
        <div className={classes.payment__body}>
          <div className={[classes.payment__title, "font-48"].join(" ")}>
            {t("main_four__title")}
          </div>

          {/* <div className={classes.paymentrow}>
            <div className={classes.paymentrow__left}>
              <p
                data-number="1"
                data-value="visa"
                className={classes.active}
                onMouseEnter={() => {
                  setNum(1);
                }}
              >
                VISA
              </p>
              <p
                data-number="2"
                data-value={"mastercard"}
                onMouseEnter={() => {
                  setNum(2);
                }}
              >
                Mastercard
              </p>
              <p
                data-number="3"
                data-value={"maestro"}
                onMouseEnter={() => {
                  setNum(3);
                }}
              >
                Maestro
              </p>
            </div>
            <div className={classes.paymentrow__right}>
              <div
                className={
                  num === 1
                    ? [
                        classes.paymentrow__img,
                        classes.visa,
                        classes.active,
                      ].join(" ")
                    : [classes.paymentrow__img, classes.visa].join(" ")
                }
              >
                <img src="img/4/1.png" alt="" />
              </div>
              <div
                className={
                  num === 2
                    ? [
                        classes.paymentrow__img,
                        classes.mastercard,
                        classes.active,
                      ].join(" ")
                    : [classes.paymentrow__img, classes.mastercard].join(" ")
                }
              >
                <img src="img/4/2.png" alt="" />
              </div>
              <div
                className={
                  num === 3
                    ? [
                        classes.paymentrow__img,
                        classes.maestro,
                        classes.active,
                      ].join(" ")
                    : [classes.paymentrow__img, classes.maestro].join(" ")
                }
              >
                <img src="img/4/3.png" alt="" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className={classes.paymentmobrow__wrap}>
          <div className={classes.paymentmobrow}>
            <div
              className={[classes.paymentmobrow__column, classes.visa].join(
                " "
              )}
            >
              <img src="img/4/1.png" alt="" />
            </div>
            <div
              className={[
                classes.paymentmobrow__column,
                classes.mastercard,
              ].join(" ")}
            >
              <img src="img/4/2.png" alt="" />
            </div>
            <div
              className={[classes.paymentmobrow__column, classes.maestro].join(
                " "
              )}
            >
              <img src="img/4/3.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
