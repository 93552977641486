// import React, { useEffect } from "react";
// import classes from "./StartTrading.module.scss";

// const StartTrading = () => {
//   function resizeIframe(obj) {
//     obj.style.height =
//       obj.contentWindow.document.documentElement.scrollHeight + "px";
//   }
//   return (
//     <div>
//       <iframe
//         className={classes.iframe}
//         style={{ width: "100%" }}
//         src="land.html"
//         frameborder="0"
//         scrolling="no"
//         sandbox="allow-same-origin"
//         onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
//       ></iframe>
//     </div>
//   );
// };

// export default StartTrading;

import React, { Component } from "react";
import ReactDOM from "react-dom";
import classes from "./StartTrading.module.scss";
export default class StartTrading extends Component {
  constructor() {
    super();
    this.state = {
      iFrameHeight: "0px",
    };
  }

  render() {
    return (
      <iframe
        className={classes.iframe}
        style={{
          width: "100%",
          height: this.state.iFrameHeight,
          overflow: "visible",
        }}
        onLoad={() => {
          const obj = ReactDOM.findDOMNode(this);
          this.setState({
            iFrameHeight: obj.contentWindow.document.body.scrollHeight + "px",
          });
        }}
        ref="iframe"
        src="land.html"
        width="100%"
        height={this.state.iFrameHeight}
      />
    );
  }
}
