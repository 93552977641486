import React from "react";
import classes from "./Sources.module.scss";
import pentagon from "../../../assets/AnalyticsPage/pentagon.png";
import { useTranslation } from "react-i18next";
const Sources = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.sources}>
        <div className={[classes.header, "font-48"].join(" ")}>
          {t("analytics_three__title")}
        </div>
        <div className={classes.cards}>
          <div className={classes.card}>
            <div className={classes.cardBg}></div>
            <div className={classes.cardHeader}>
              {t("analytics_three_item1__title")}
            </div>
            <div className={classes.cardText}>
              {t("analytics_three_item1__subtitle")}
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardBg}></div>
            <div className={classes.cardHeader}>
              {t("analytics_three_item2__title")}
            </div>
            <div className={classes.cardText}>
              {t("analytics_three_item2__subtitle")}
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardBg}></div>
            <div className={classes.cardHeader}>
              {t("analytics_three_item3__title")}
            </div>
            <div className={classes.cardText}>
              {t("analytics_three_item3__subtitle")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sources;
