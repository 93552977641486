import React, { useEffect, useRef } from "react";
import Banner from "../../components/AnalyticsPage/Banner/Banner";
import MarketOverview from "../../components/AnalyticsPage/MarketOverview/MarketOverview";
import Sources from "../../components/AnalyticsPage/Sources/Sources";
import WhatFor from "../../components/AnalyticsPage/WhatFor/WhatFor";
import classes from './AnalyticsPage.module.scss';
import { initAnimation } from "../../mixins/animationMethods";
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

const AnalyticsPage = () => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref !== null && document.documentElement.clientWidth > 1200) {
            initAnimation(ref, 3);
        }
    }, []);
    return (
        <div ref={ref}>
            <NavBar />
            <Banner />
            <div id='animBlock_0'>
                <WhatFor />
            </div>
            <div id='animBlock_1'>
                <Sources />
            </div>
            <div id='animBlock_2'>
                <MarketOverview />
            </div>
            <Footer />
        </div>
    );
};

export default AnalyticsPage;
