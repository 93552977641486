import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import classes from "./StartPage.module.scss";
import Trade from "../../components/StartPageComponents/Trade/Trade";
import About from "../../components/StartPageComponents/About/About";
import Tabs from "../../components/TradingPage/Tabs/Tabs";
import Choose from "../../components/StartPageComponents/Choose/Choose";
import Today from "../../components/StartPageComponents/Today/Today";
const StartPage = () => {
  let scroll = 0;
  const [ballAnimeNumber, setAnimeNumber] = useState(0);
  const [active, setActive] = useState(false);
  return (
    <div
      className={classes.start}
      onScroll={(e) => {
        if (e.currentTarget.scrollTop > scroll) {
          if (
            document.querySelector("#tap").getBoundingClientRect().top < 300
          ) {
            setAnimeNumber(3);
          }
          if (
            document.querySelector("#tap1").getBoundingClientRect().top < 300
          ) {
            setActive(true);
          }
        } else {
          if (
            document.querySelector("#tap").getBoundingClientRect().top > 300
          ) {
            setAnimeNumber(2);
          }
          if (
            document.querySelector("#tap1").getBoundingClientRect().top > 300
          ) {
            setActive(false);
          }
        }
        scroll = e.currentTarget.scrollTop;
      }}
    >
      <NavBar />

      <Trade
        ballAnimeNumber={ballAnimeNumber}
        setAnimeNumber={setAnimeNumber}
      />
      <div id="tap">
        <About />
      </div>

      {/* <Tabs /> */}
      <Choose />
      <div id="tap1">
        <Today active={active} setActive={setActive} />
      </div>

      <Footer />
    </div>
  );
};

export default StartPage;
