import React, { useState } from "react";
import classes from "./Most.module.scss";
import { useTranslation } from "react-i18next";
const Most = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "about_six_tab1__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          listNumbers: [
            `about_six_tab1__text1`,
            `about_six_tab1__text2`,
            `about_six_tab1__text3`,
          ],
          text: "",
        },
      ],
    },
    {
      id: 2,
      title: "about_six_tab2__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `about_six_tab2__text1`,
        },
      ],
    },
    {
      id: 3,
      title: "about_six_tab3__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab3__text1`,
          listCircle: [
            `about_six_tab3__text2`,
            `about_six_tab3__text3`,
            `about_six_tab3__text4`,
            `about_six_tab3__text5`,
          ],
        },
      ],
    },
    {
      id: 4,
      title: "about_six_tab4__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab4__text1`,
        },
        {
          id: 2,
          isStart: false,
          text: `about_six_tab4__text2`,
        },
      ],
    },
    {
      id: 5,
      title: "about_six_tab5__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab5__text1`,
        },
      ],
    },
    {
      id: 6,
      title: "about_six_tab6__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab6__text1`,
        },
      ],
    },
    {
      id: 7,
      title: "about_six_tab7__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab7__text1`,
          listCircle: [
            `about_six_tab7__text2`,
            `about_six_tab7__text3`,
            `about_six_tab7__text4`,
          ],
        },
      ],
    },
    {
      id: 8,
      title: "about_six_tab8__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: "about_six_tab8__text1",
        },
      ],
    },
    {
      id: 9,
      title: "about_six_tab9__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab9__text1`,
        },
      ],
    },
    {
      id: 10,
      title: "about_six_tab10__title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          text: `about_six_tab10__text1`,
        },
      ],
    },
  ]);

  return (
    <div className={classes.most}>
      <div className={classes.mostBody}>
        <div className="container">
          <div className={[classes.mostTitle, "header-default"].join(" ")}>
            {t("about_six__title")}
          </div>
          <div className={classes.mostSubtitle}>{t("about_six__subtitle")}</div>
          <div className={classes.mostText}>{t("about_six__text")}</div>
          {spoilers.map((i) => {
            return (
              <div key={i.id} className={classes.mostSpoiler}>
                <div
                  className={
                    i.active
                      ? [classes.mostSpoilerTitle, classes.active].join(" ")
                      : classes.mostSpoilerTitle
                  }
                  onClick={() => {
                    setSpoilers(
                      spoilers.map((curI) => {
                        if (curI.id === i.id) {
                          return { ...curI, active: !curI.active };
                        }
                        return { ...curI, active: false };
                      })
                    );
                  }}
                >
                  {t(i.title)}
                </div>
                {i.contents.map((subI) => {
                  return (
                    <div
                      key={subI.id}
                      className={
                        i.active
                          ? subI.isStart
                            ? [
                                classes.mostSpoilerContent,
                                classes.active,
                                classes.star,
                              ].join(" ")
                            : [classes.mostSpoilerContent, classes.active].join(
                                " "
                              )
                          : classes.mostSpoilerContent
                      }
                    >
                      {t(subI.text)}
                      {subI.listNumbers && (
                        <ol>
                          {subI.listNumbers.map((listNum) => {
                            return <li>{t(listNum)}</li>;
                          })}
                        </ol>
                      )}
                      {subI.listCircle && (
                        <ul style={{ listStyleType: "circle" }}>
                          {subI.listCircle.map((listNum) => {
                            return <li>{t(listNum)}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Most;
