import React from "react";
import Love from "../../components/FaqPage/Love/Love";
import Most from "../../components/FaqPage/Most/Most";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

const FaqPage = () => {
  return (
    <div>
      <NavBar />
      <Love />
      <Most />
      <Footer />
    </div>
  );
};

export default FaqPage;
