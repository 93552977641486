import { Link } from 'react-router-dom';
import classes from './Footer.module.scss'
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;
    return (
        <footer className={classes.footer}>
            <div className={classes.footer__body}>
                <div className={[classes.container, "container"].join(" ")}>
                    <div className={classes.footerrow}>
                        <div className={classes.footerrowleft}>
                            <div className={classes.footerrowleft__logo}>
                                <img src="img/1/1w.png" alt="" />
                            </div>
                            <a href={`mailto:support@${hostname}`} className={classes.footerrowleft__info}>
                                support@{hostname}</a>
                            <a href="tel:441519479677" className={classes.footerrowleft__info}>+441519479677</a>
                        </div>
                        <div className={classes.footerrowcenter}>
                            <div className={classes.footerrowcenter__info}>
                                {/* {t("footer_top_center__item1")} */}
                                <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                                    {t('footer_privacy_policy')}
                                </a>
                            </div>
                            <div className={classes.footerrowcenter__info}>
                                {/* {t("footer_top_center__item2")} */}
                                <a href="/docs/AML_POLICY.pdf" target="_blank">
                                    {t('footer_aml')}
                                </a>
                            </div>
                            <div className={classes.footerrowcenter__info}>
                                {/* {t("footer_top_center__item3")} */}
                                <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                                    {t('footer_terms')}
                                </a>
                            </div>
                            {/* <div className={classes.footerrowcenter__info}>
                                {t("footer_top_center__item4")}
                            </div> */}
                        </div>
                        <div className={classes.footerrowright}>
                            <div className={classes.footerrowright__wrapper}>
                                <Link to="/trading" activeClassName={classes.active} className={classes.footerrowcenter__info}>{t("footer_top_right__item1")}</Link>
                                <Link to="/analytics" activeClassName={classes.active} className={classes.footerrowcenter__info}>{t("footer_top_right__item2")}</Link>
                                <Link to="/about" activeClassName={classes.active} className={classes.footerrowcenter__info}>{t("footer_top_right__item3")}</Link>

                                {/* <Link to="/faq" activeClassName={classes.active} className={classes.footerrowcenter__info}>{t("footer_top_right__item5")}</Link> */}
                            </div>
                        </div>
                    </div>
                    <div className={classes.footerrowsecond}>
                        <div className={classes.footerrowsecondleft}>
                            <div className={classes.footerrowsecondleft__title}>
                                {t("footer_bot_left__title")}
                            </div>
                            <div className={classes.footerrowsecondleft__subtitle}>
                                {t("footer_bot_left__subtitle")}
                            </div>
                        </div>
                        {/* <div className={classes.footerrowsecondcenter}>
                            <div className={classes.footerrowsecondcenter__title}>{t("footer_bot_center__title")}</div>
                            <div className={classes.footerrowsecondcenter__subtitle}>
                                {t("footer_bot_center__subtitle")}
                            </div>
                        </div> */}
                        <div className={classes.footerrowsecondright}>
                            {/* <div className={classes.footerrowsecondright__left}>
                                <div className={classes.footerrowsecondright__title}>
                                    <Trans
                                        components={{ span: <span></span> }}
                                    >{t('footer_bot_right__title')}</Trans>
                                </div>
                                <div className={classes.footerrowsecondright__subtitle}>
                                    {t("footer_bot_right__subtitle")}
                                </div>
                            </div>
                            <div className={classes.footerrowsecondright__right}>
                                <div className={classes.footerrowsecondright__apps}>
                                    <img src="img/6/2.png" alt="" />
                                    <img src="img/6/3.png" alt="" />
                                </div>
                            </div> */}
                            <div className={classes.footerrowsecondright__copyright}>
                                {t("footer_bot_right__copy")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
    )
};

export default Footer;
