import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./Winner.module.scss";
import "./Winner.scss";
import img1 from "../../../assets/trading/4/1.png";
import img2 from "../../../assets/trading/4/2.png";
import img3 from "../../../assets/trading/4/3.png";
import { Trans, useTranslation } from "react-i18next";
const Winner = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [number, setNumber] = useState(0);
  const { t } = useTranslation();
  return (
    <div className={classes.winner}>
      <div className={classes.winnerBody}>
        <div className={["container"].join(" ")}>
          <div className={[classes.winnerTitle, "font-48"].join(" ")}>
            <Trans components={{ span: <span></span> }}>
              {t("trading_six__title")}
            </Trans>
          </div>
          <div className={classes.winnerSubtitle}>
            {t("trading_six__subtitle")}
          </div>
          <div className={classes.winnerHide}>
            <div className={classes.winnerRow}>
              <div className={classes.winnerRow}>
                <div
                  onMouseEnter={() => {
                    setNumber(1);
                  }}
                  onMouseLeave={() => {
                    setNumber(0);
                  }}
                  className={
                    number === 2 || number === 3
                      ? [classes.winnerRowColumn, classes.blur].join(" ")
                      : classes.winnerRowColumn
                  }
                >
                  <img src={img1} alt="" />
                </div>
                <div
                  onMouseEnter={() => {
                    setNumber(2);
                  }}
                  onMouseLeave={() => {
                    setNumber(0);
                  }}
                  className={
                    number === 1 || number === 3
                      ? [classes.winnerRowColumn, classes.blur].join(" ")
                      : classes.winnerRowColumn
                  }
                >
                  <img src={img2} alt="" />
                </div>
                <div
                  onMouseEnter={() => {
                    setNumber(3);
                  }}
                  onMouseLeave={() => {
                    setNumber(0);
                  }}
                  className={
                    number === 1 || number === 2
                      ? [classes.winnerRowColumn, classes.blur].join(" ")
                      : classes.winnerRowColumn
                  }
                >
                  <img src={img3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.winnerVisible} id="winner1">
          <Slider
            dots={true}
            asNavFor={nav2}
            ref={(slider1) => setNav1(slider1)}
          >
            <div>
              <div
                onMouseEnter={() => {
                  setNumber(1);
                }}
                onMouseLeave={() => {
                  setNumber(0);
                }}
                className={
                  number === 2 || number === 3
                    ? [classes.winnerRowColumn, classes.blur].join(" ")
                    : classes.winnerRowColumn
                }
              >
                <img src={img1} alt="" />
              </div>
            </div>
            <div>
              <div
                onMouseEnter={() => {
                  setNumber(2);
                }}
                onMouseLeave={() => {
                  setNumber(0);
                }}
                className={
                  number === 1 || number === 3
                    ? [classes.winnerRowColumn, classes.blur].join(" ")
                    : classes.winnerRowColumn
                }
              >
                <img src={img2} alt="" />
              </div>
            </div>
            <div>
              <div
                onMouseEnter={() => {
                  setNumber(3);
                }}
                onMouseLeave={() => {
                  setNumber(0);
                }}
                className={
                  number === 1 || number === 2
                    ? [classes.winnerRowColumn, classes.blur].join(" ")
                    : classes.winnerRowColumn
                }
              >
                <img src={img3} alt="" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Winner;
