import React from "react";
import classes from "./Love.module.scss";
import img from "../../../assets/trading/1/1.png";
import { useTranslation } from "react-i18next";
const Love = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.love}>
      <div className={classes.loveBody}>
        <div className="container">
          <div className={classes.loveWrapper}>
            <div className={classes.loveTitle}>{t("about_five__title")}</div>
            <div className={classes.loveSubtitle}>
              {t("about_five__subtitle")}
            </div>
            <div className={classes.loveWrapperBall}>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Love;
